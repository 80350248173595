.navBar{
    z-index: 1201 !important;
    .columns-info{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;

        .MuiInputBase-root{
            color: white !important
        }

        .menu{
            padding: 0;
            display: flex;
    
            .MuiListItem-root{
                padding: 0 8px;
                
                &:last-child{
                    border-left: 1px solid #f9ba31;
                    padding-left: 15px;
                }
                .MuiFab-primary{
                    height: 45px;
                    width: 45px;
                }
                .MuiFab-primary{
                    color: #4472c4 !important;
                    background-color: transparent !important;
                }
                .MuiFab-root{
                    box-shadow: none;
                }
            }
        }
    }

    .column-logo{
        display: flex;
        align-items: center;

        .cdpLogo{
            max-height: 30px !important;
            margin: 0px 10px !important;
        }

        .menuButton{
            margin-left: -16px !important;
            background-color: #4472c4 ;
            border-radius: 0 10px 0 0 !important;
            min-height: 60px;
            min-width: 72px;
        }
    }
    .tittle{
        display: flex;
        align-items: center;
        position: relative;
        right: 3%;
        .text{
            font-weight: bolder;
        }
    }
    .exitIcon{
        color: #4472c4 ;
    }
    .userName{
        color: #4472c4 ;
    }
    
}

.MuiAppBar-colorPrimary{
    color: rgba(19,20,21,100) !important;
    background-color: #ffffff  !important;
}

.appBarLocked{
    width: calc(100% - 320px);
}