@import '/src/styles/Header.scss';
@import '/src/styles/Layout.scss';
@import '/src/styles/NavBar.scss';

html{
    box-sizing: border-box;
}

*,
*::before,
*::after{
    box-sizing: inherit;
}

body{
    margin: 0;
    line-height: 1.5;
    color: black;
    background-color: rgba(238, 238, 239, 100);
    .MuiPaper-elevation1{
        width: 100%;
    }

    .MuiFormGroup-root{
        margin-top: 8px;
        margin-bottom: 18px;
    }

    .p-inputtext{
        width: 100%;
    }
}