.content{
    margin-top: 20%;
    margin-left: 6%;
    width: 94%;
    // min-height: 90vh;
    .introduction{
        margin-bottom: 1.5rem;
    }
}

.nested{
    padding-left: 40px !important;
}

.MuiListItemIcon-root{
    min-width: 42px !important;
}
.MuiFormControl-root{
    width: 100%;
}

.MuiTablePagination-spacer{
    display: none;
}

.MuiTablePagination-root{
    padding: 0 15px !important;
}

.MuiDialogContent-root .MuiPaper-root .MuiTableContainer-root{
    max-height: 100%;
}

.MuiTableContainer-root{
    border-radius: 4px;
    max-height: 76vh;

    .MuiTableHead-root{
        .MuiTableCell-root{
            border-bottom: 1px solid black;
            font-weight: 500;
            color: black;
        }
    }
    .MuiTableCell-root{
        white-space: nowrap;
        &.table-actions{
            width: 150px;
        }
    }
}

.sticky-col{
    position: sticky !important;
    left: 0;
    z-index: 2 !important;
    background-color: white !important;

    &.MuiTableCell-stickyHeader{
        background-color: #fafafa !important;
        z-index: 3 !important;
    }
}

.title-page{
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 17px;
    .MuiTypography-root{
        &.MuiTypography-h4{
            font-size: 1.6rem;
        }
    }
}

.buttonCont{
    display: flex;
    padding-bottom: 10px;
    justify-content: flex-end;
}