.drawer{
    flex-shrink: 0 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    height: 50%;
    .MuiDrawer-paper{
        position: fixed;
        z-index: 1000;
        margin-top: 65px;
        width: 6%;
        >.general-list{
            overflow-y: auto;
            margin-bottom: 50px;
        }
    }
}

.drawerClose{
    width: 0px;
    margin-left: -20px;
    overflow: hidden !important;
    .general-list{
        overflow: hidden !important;
    }
}